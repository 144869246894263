export default function validate(values) {
  let errors = {};
  if (!values.full_name) {
    errors.full_name = 'Name is required';
  }
  if (!values.company_name) {
    errors.company_name = 'Company name is required';
  }
  if (!values.email_address) {
    errors.email_address = 'Email address is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email_address)) {
    errors.email_address = 'Email address is invalid';
  }
  return errors;
};
