import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact Us" />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="mb-10 lg:mb-20">
            <h2 className="text-3xl font-light text-gray-900 sm:text-4xl">
              Contact
              <span className="text-purple-700"> SmartPay 21</span>
            </h2>
            <p className="mt-3 max-w-3xl text-lg text-gray-500">
              The SmartPay 21 API provides a modern, simple interface that delivers the latest in contactless payment
              functionality. Contact us to get started with our APIs, or to schedule a demo.
            </p>
            <p className="mt-5 max-w-3xl text-lg text-gray-500">
              We know that many clients have unique needs. Tell us more about your project,
              and we will get back to you soon with some ideas of how we can help.
            </p>
          </div>
          <div>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>

  </Layout>
)

export default ContactPage
