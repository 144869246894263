import React, { useState } from "react"
import useForm from "./utils/useForm";
import validate from "./utils/contactFormValidation";
import Modal from "./modal/modal";
import firebase from "gatsby-plugin-firebase";

const ContactForm = () => {
  const { values, errors, isSubmitting, handleChange, handleSubmit } = useForm(sendContactInfo, validate);
  const [showModal, setShowModal] = useState(false);

  function sendContactInfo() {
    firebase.analytics().logEvent('contact_form_submitted');
    const callable = firebase.functions().httpsCallable("contactEmail");
    return callable(values).then(response => {
      if (response.data.success) {
        setShowModal(true);
      }
    });
  }

  return (
    <div>
      <Modal showModal={showModal}/>
      <form action="#"
            method="POST"
            className="grid grid-cols-1 gap-y-6"
            onSubmit={handleSubmit}
      >
        <div>
          <label htmlFor="full_name" className="sr-only">Full name</label>
          <input type="text" name="full_name" id="full_name" autoComplete="name"
                 className={`block w-full shadow-sm py-3 px-4 rounded-md ${errors.full_name ? "placeholder-red-300 focus:ring-red-500 focus:border-red-500 border-red-400" : "placeholder-gray-500 focus:ring-purple-500 focus:border-purple-500 border-gray-300"}`}
                 placeholder="Full name"
                 value={values.full_name || ""}
                 onChange={handleChange}
          />
          {errors.full_name && (
            <p className="mt-2 text-red-400 text-sm">{errors.full_name}</p>
          )}
        </div>
        <div>
          <label htmlFor="company_name" className="sr-only">Company</label>
          <input type="text" name="company_name" id="company_name" autoComplete="name"
                 className={`block w-full shadow-sm py-3 px-4 rounded-md ${errors.company_name ? "placeholder-red-300 focus:ring-red-500 focus:border-red-500 border-red-400" : "placeholder-gray-500 focus:ring-purple-500 focus:border-purple-500 border-gray-300"}`}
                 placeholder="Company name"
                 value={values.company_name || ""}
                 onChange={handleChange}
          />
          {errors.company_name && (
            <p className="mt-2 text-red-400 text-sm">{errors.company_name}</p>
          )}
        </div>
        <div>
          <label htmlFor="email_address" className="sr-only">Email</label>
          <input id="email_address" name="email_address" type="email" autoComplete="email"
                 className={`block w-full shadow-sm py-3 px-4 rounded-md ${errors.full_name ? "placeholder-red-300 focus:ring-red-500 focus:border-red-500 border-red-400" : "placeholder-gray-500 focus:ring-purple-500 focus:border-purple-500 border-gray-300"}`}
                 placeholder="Email"
                 value={values.email_address || ""}
                 onChange={handleChange}
          />
          {errors.email_address && (
            <p className="mt-2 text-red-400 text-sm">{errors.email_address}</p>
          )}
        </div>
        <div>
          <label>
            <span className="sr-only">Project Info</span>
            <textarea
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
              name="project_info"
              rows="4"
              placeholder="Tell us about your project."
              value={values.project_info || ""}
              onChange={handleChange}
            />
          </label>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input id="request_demo"
                     className="h-4 w-4 text-purple-700 focus:ring-indigo-500 border-gray-300 rounded"
                     name="request_demo"
                     type="checkbox"
                     checked={values.request_demo}
                     onChange={handleChange}
              />
              <label htmlFor="request_demo" className="ml-2 block text-sm text-gray-900">
                I would like a demo.
              </label>
            </div>
          </div>
        </div>
        <div>
          <button type="submit"
                  className={`inline-flex justify-center py-3 px-6 border border-transparent text-base font-medium rounded-md text-white ${isSubmitting ? "pointer-events-none bg-gray-400" : "shadow-sm bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}`}>
            Submit
          </button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm;
