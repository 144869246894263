import {useState, useEffect} from "react";

const useForm = (callback, validate) => {
  const [values, setValues] = useState({ request_demo: true });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0  && isSubmitting && !isSending) {
      setIsSending(true);
      callback();
    }
  }, [errors, callback, isSubmitting, isSending]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setIsSubmitting(true);
    setErrors(validate(values));
  };

  const handleChange = (event) => {
    event.persist();
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setValues(values => ({ ...values, [event.target.name]: value }));
  };

  return {
    handleChange,
    handleSubmit,
    isSubmitting,
    values,
    errors,
  }
};

export default useForm;
